import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import "./Topbar.css"
import {Config} from "../../../Config/config"
import Dropdown from "./Dropdown/Dropdown"
import Loading from "../../Loading/Loading"
import {turkceToEnglish} from "../../PathHelper/PathHelper"
const TeamsTable = () => {
  const [categories, setCategories] = useState([])

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${Config.ApiBaseUrl}/category/?is_show_menu=true&is_active=true`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${Config.Token}`,
            },
          }
        )

        if (!response.ok) {
          throw new Error("Kategori verileri alınamadı.")
        }

        const data = await response.json()

        // Sadece order <= 11 olan kategorileri al ve order'a göre sırala
        const filteredCategories = data
          .filter((category) => category.order <= 11)
          .sort((a, b) => a.order - b.order)

        setCategories(filteredCategories)
      } catch (error) {
        console.error("Hata:", error)
      }
    }

    fetchCategories()
  }, [])

  return (
    <div className="Topbar">
      <ul>
        {/* <button className="button-menu-link">
          <Link to="/">
            <span>ANA SAYFA</span>
          </Link>
        </button> */}
        {categories.length > 0 ? (
          categories.map((cat, index) => (
            <li key={index}>
              <Link
                to={
                  cat.name === "Gündem"
                    ? "/"
                    : cat.name === "Yazarlar"
                    ? "/yazarlar"
                    : `/${cat.id}/${turkceToEnglish(cat.name)}`
                }
                onClick={() => localStorage.setItem("categoryName", cat.name)}
                className="menu-link"
              >
                {cat.name === "Gündem"
                  ? "ANA SAYFA"
                  : cat.name.toLocaleUpperCase("tr-TR")}
              </Link>
            </li>
          ))
        ) : (
          <li>
            <Loading />
          </li>
        )}
        <div className="dropdown">
          <Dropdown />
        </div>
      </ul>
    </div>
  )
}

export default TeamsTable
