import React, {useEffect, useState} from "react"
import {useParams, useNavigate} from "react-router-dom"
import "./CategoryPage.css"
import Side from "../../Components/Navbar/PlayersList/Side/Side"
import TitleBox from "../../Components/TitleBox/TitleBox"
import {Config} from "../../Config/config"
import CategorySlider from "./CategorySlider/CategorySlider"
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory"
import HaberDefaultImage from "../../img/kıbrıslı-gazetesi-haber-default-img-min.jpg"
import OzelHaberImage from "../../Components/OzelHaberEtiketi/OzelHaberImage"
import Loading from "../../Components/Loading/Loading"
import {turkceToEnglish} from "../../Components/PathHelper/PathHelper"
import Pagination from "../../Components/Pagination/Pagination"
import OzelHaberEtiketi from "../../Components/OzelHaberEtiketi/OzelHaberEtiketi"
import CategoryEtiketi from "../../Components/CategoryEtiketi/CategoryEtiketi"
const CategoryPage = () => {
  const {categoryId} = useParams()
  const [articles, setArticles] = useState([])
  const [articlesSlider, setArticlesSlider] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [totalPage, setTotalPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [initialLoad, setInitialLoad] = useState(true) // Eklenen state
  const navigate = useNavigate()
  const originalCategoryName = localStorage.getItem("categoryName")

  const fetchSliderData = async (categoryId) => {
    // console.log(`${categoryId} slider data çekiliyor`);
    try {
      const response = await fetch(
        `${Config.ApiBaseUrl}/postbasic/?postcategoryid=${categoryId}&is_manset=true&is_published=true`,
        {
          method: "GET",
          headers: {
            Authorization: `Token ${Config.Token}`,
          },
        }
      )
      const data = await response.json()
      setArticlesSlider(data?.results || [])
    } catch (err) {
      setError(err.message)
    }
  }

  const fetchData = async (currentPage) => {
    // console.log(" data çekiliyor");
    setLoading(true)
    try {
      let url = `${Config.ApiBaseUrl}/postbasic/?is_active=true&is_published=true&page=${currentPage}&page_size=10`

      const categoryMap = {
        11: "11,17,19,28,27,16,24",
        14: "14,26",
        7: "7,15",
        3: "3,4,8",
      }

      if (categoryId && categoryMap[categoryId]) {
        url = `${Config.ApiBaseUrl}/postbasic/?postcategoryid=${categoryMap[categoryId]}&is_active=true&is_published=true&page=${currentPage}&page_size=10`
      } else if (categoryId) {
        url = `${Config.ApiBaseUrl}/postbasic/?postcategoryid=${categoryId}&is_active=true&is_published=true&page=${currentPage}&page_size=10`
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Token ${Config.Token}`,
        },
      })
      const data = await response.json()

      const uniqueArticles = Array.isArray(data.results)
        ? [...new Map(data.results.map((item) => [item.id, item])).values()]
        : []

      setArticles(uniqueArticles)
      setTotalPage(data.pages || 1)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (initialLoad) {
      // Sadece ilk render'da veri çekiyoruz
      fetchSliderData(categoryId)
      setCurrentPage(1)
      fetchData(1)
      setInitialLoad(false) // İlk yüklemeyi bir kez yap
    } else {
      // `categoryId` değiştiğinde yalnızca veri çekiyoruz
      fetchSliderData(categoryId)
      fetchData(1)
    }
  }, [categoryId]) // `categoryId` değiştiğinde tetiklenir

  useEffect(() => {
    if (!initialLoad) {
      // `currentPage` değiştiğinde veri çekilir
      fetchData(currentPage)
      window.scrollTo(0, 0)
    }
  }, [currentPage]) // `currentPage` değiştiğinde tetiklenir
  const handleNewsClick = (id, title) => {
    const formattedTitle = turkceToEnglish(title)
    navigate(`/haber/${id}/${formattedTitle}`)
  }

  return (
    <>
      <TopRelatedCategory />
      <div className="category-page-titleBox">
        <TitleBox title={originalCategoryName} />
      </div>

      <div className="category-page">
        <div className="catgeoryAd">
          <div className="catgeoryAd-articles">
            <CategorySlider articles={articlesSlider} />
            {loading && <Loading />}
            {error && <p>{error}</p>}
            <div className="catgeoryAd-articles-grid">
              {articles.map((article) => {
                const coverImage = article.postimage?.find(
                  (img) => img.is_cover_image
                )?.uploaded_image?.image
                const ReporterImg = article.reporter__image
                return (
                  <div
                    key={article.id}
                    className="catgeoryAd-article-card"
                    onClick={() => handleNewsClick(article.id, article.header)}
                  >
                    <CategoryEtiketi headerText={originalCategoryName} />
                    <img
                      src={coverImage || HaberDefaultImage}
                      alt={article.header || "Haber Resmi"}
                      className="catgeoryAd-article-image"
                    />
                    {ReporterImg && (
                      <OzelHaberImage ReporterImg={ReporterImg} />
                    )}
                    {article.postcategory?.find(
                      (categoryItem) => categoryItem.category === 12
                    ) &&
                      !ReporterImg && (
                        <OzelHaberEtiketi showDefaultIfEmpty={true} />
                      )}
                    <h3 className="catgeoryAd-article-title">
                      {article.header}
                    </h3>
                  </div>
                )
              })}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPage}
              onPageChange={setCurrentPage}
            />
          </div>
        </div>
        <div className="haber-details-side-wrapper">
          <Side />
        </div>
      </div>
    </>
  )
}

export default CategoryPage
