import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import "./Topbarbottom.css"
import line from "../../../img/line.png"
import {Config} from "../../../Config/config"
import Loading from "../../Loading/Loading"
import {turkceToEnglish} from "../../PathHelper/PathHelper"
const Topbarbottom = () => {
  const [categories, setCategories] = useState([])

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${Config.ApiBaseUrl}/category/?is_show_menu=true&is_active=true`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${Config.Token}`,
            },
          }
        )

        if (!response.ok) {
          throw new Error("Kategori verileri alınamadı.")
        }
        const data = await response.json()
        const filteredCategories = data.filter(
          (category) => category.order > 11
        )

        setCategories(filteredCategories)
      } catch (error) {
        console.error("Hata:", error)
      }
    }

    fetchCategories()
  }, [])

  return (
    <div className="Topbarbottom">
      <div className="topbar-ad">
        {categories.length > 0 ? (
          categories.map((category) => (
            <li key={category.id} className="topbar-item">
              <Link
                to={`/${category.id}/${turkceToEnglish(category.name)}`}
                onClick={() =>
                  localStorage.setItem("categoryName", category.name)
                }
                className="dropdown-link"
              >
                <div className="topbar-flex-center">
                  {category.name.toLocaleUpperCase("tr-TR")}
                  <img src={line} alt="Line" className="topbar-line-image" />
                </div>
              </Link>
            </li>
          ))
        ) : (
          <li>
            <Loading />
          </li>
        )}
        {/* <button className="topbar-item">
          <Link to="/20/vefatlar">
            <span className="button-dropdown-link">VEFATLAR</span>
          </Link>
        </button> */}
      </div>
    </div>
  )
}

export default Topbarbottom
