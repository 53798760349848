import React, {useState, useEffect, useRef} from "react"
import {FaBars, FaSearch, FaDownload} from "react-icons/fa"
import {Link, useLocation} from "react-router-dom" // Import Link here
import "./Navmobile.css"
import Layer from "../../../img/Layer.png"
import indir from "../../../img/indir.png"
import {Config} from "../../../Config/config"
import Loading from "../../Loading/Loading"
import {turkceToEnglish} from "../../PathHelper/PathHelper"
const Navmobile = () => {
  const location = useLocation()
  const [menuOpen, setMenuOpen] = useState(false)
  const [navs, setNavs] = useState([])
  const [openSection, setOpenSection] = useState(null)
  const [isVisible, setIsVisible] = useState(true)

  const menuRef = useRef(null) // Menü dışındaki tıklamaları algılamak için ref

  const toggleMenu = () => setMenuOpen(!menuOpen)
  const closeMenu = () => setMenuOpen(false)
  const closeContainer = () => setIsVisible(false)

  useEffect(() => {
    // sayfa değiştiğinde menüyü kapat
    setMenuOpen(false)
  }, [location.pathname])
  useEffect(() => {
    // Dış tıklamaları dinleyelim
    const handleClickOutside = (event) => {
      if (
        menuOpen &&
        menuRef.current && // Menü var ve dışarıya tıklanıyorsa
        !menuRef.current.contains(event.target) && // Menü dışında bir yere tıklanıyorsa
        !event.target.closest(".hamburger-icon") // Menü açma butonuna tıklanmadığında
      ) {
        setMenuOpen(false) // Menü kapanacak
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [menuOpen])

  useEffect(() => {
    fetch(`${Config.ApiBaseUrl}/category/?is_active=true`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Config.Token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error("Veri alınamadı")
        return response.json()
      })
      .then((data) => setNavs(data))
      .catch((error) => {
        console.error("Hata:", error)
      })
  }, [])

  const toggleDropdown = (id) => {
    setOpenSection(openSection === id ? null : id)
  }

  return (
    <div className="navmobile-wrapper">
      {isVisible && (
        <div className="nav-mobile-download-top-container">
          <div
            className="nav-mobile-download-top-close-button"
            onClick={closeContainer}
          >
            X
          </div>
          <div className="nav-mobile-download-top-close-indir">
            <img src={indir} alt="Mobile Indir" />
          </div>
          <div className="nav-mobile-download-top-content">
            <h1>Kıbrıslı Gazetesi</h1>
            <p>Kıbrıs’ın gündemini yakalayın!</p>
          </div>
          <div className="nav-mobile-download-top-close-indir-buttomn">
            <FaDownload size={10} className="search-icon" /> indir
          </div>
        </div>
      )}

      <div className="navmobile-container">
        <div className="navmobile-container-wrapper-centered">
          <div className="layer-logo">
            {/* Wrap the logo with Link to navigate to the homepage */}
            <Link to="/">
              <img src={Layer} alt="Layer Logo" className="layer-img" />
            </Link>
          </div>

          <div className="navmobile-header">
            <FaBars
              size={30}
              className={`hamburger-icon ${menuOpen ? "active" : ""}`}
              onClick={toggleMenu}
            />
            {/*
          <FaSearch size={30} className="search-icon" />
            
            */}
          </div>
        </div>
      </div>

      <div
        className={`mobile-menu ${menuOpen ? "open" : ""}`}
        ref={menuRef} // Menüye referans veriyoruz
      >
        <button className="nav-mobile-close-btn" onClick={closeMenu}>
          X
        </button>
        <ul>
          {/* <button className="button-nav-item">
            <Link to="/" className="navmobile-dropdown-link" onClick={closeMenu}>
             Ana Sayfa
            
            </Link>
          </button> */}
          {navs.length > 0 ? (
            [...navs]
              .sort((a, b) => a.id - b.id)
              .map((nav) => (
                <li key={nav.id} className="nav-item">
                  <Link
                    to={
                      nav.name === "Gündem"
                        ? "/"
                        : nav.name === "Yazarlar"
                        ? "/yazarlar"
                        : `/${nav.id}/${turkceToEnglish(nav.name)}`
                    }
                    onClick={() => {
                      localStorage.setItem("categoryName", nav.name)
                      closeMenu()
                    }}
                    className="navmobile-dropdown-link"
                  >
                    {nav.name === "Gündem" ? "Ana Sayfa" : nav.name}
                  </Link>
                </li>
              ))
          ) : (
            <li>
              <Loading />
            </li>
          )}
          <li>
            <Link to="/eczane" className="linkEczane">
              Nöbetçi Eczane
            </Link>
          </li>
          <li>
            <Link to="/iletisim" className="linkEczane">
              İletişim
            </Link>
          </li>
          {/* <button className="button-nav-item">
            <Link to="/vefatlar" className="navmobile-dropdown-link" onClick={closeMenu}>
             Vefatlar
            
            </Link>
          </button> */}
        </ul>
      </div>
    </div>
  )
}

export default Navmobile
