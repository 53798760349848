import React, {useEffect, useState} from "react"
import Side from "../../Components/Navbar/PlayersList/Side/Side"
import TitleBox from "../../Components/TitleBox/TitleBox"
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory"
import "./Eczane.css"
import SingleRelatedComponent from "../../Components/RelatedComponent/SingleRelatedComponent"
import {Config} from "../../Config/config"
import eczaneilac from "../../img/rentabilidad-de-una-farmacia-1024x682.jpg"

const Eczane = () => {
  const [pharmacies, setPharmacies] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selectedTab, setSelectedTab] = useState("bugun") // Varsayılan olarak bugün gösterilecek
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    const fetchPharmacies = async () => {
      try {
        const response = await fetch(
          `${Config.ApiBaseUrl}/pharmacy/?page_size=50`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${Config.Token}`,
            },
          }
        )

        if (!response.ok) {
          throw new Error("Veri alınırken hata oluştu.")
        }

        const data = await response.json()
        // Ensure data is an array, if not, use data.results if available or empty array
        setPharmacies(Array.isArray(data) ? data : data.results || [])
      } catch (err) {
        setError(err.message)
        setPharmacies([]) // Set empty array on error
      } finally {
        setLoading(false)
      }
    }

    fetchPharmacies()
  }, [])

  // Tarihleri hesapla
  const today = new Date().toISOString().split("T")[0] // "YYYY-MM-DD"
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
    .toISOString()
    .split("T")[0]
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0]

  // Seçili sekmeye göre veriyi filtrele
  const filteredPharmacies = pharmacies
    .filter((pharmacy) => {
      if (selectedTab === "dun") return pharmacy.date === yesterday
      if (selectedTab === "bugun") return pharmacy.date === today
      if (selectedTab === "yarin") return pharmacy.date === tomorrow
      return false
    })
    .sort((a, b) => b.id - a.id) // ID'ye göre azalan sırayla sıralama (en büyük ID en üstte)
  const eczaneleftcontainer = isMobile
    ? {
        display: "grid",
        gridTemplateColumns: "1fr",
        margin: "15px 5vw",
        gap: "15px",
      }
    : {
        display: "flex",
        justifyContent: "start",
        alignItems: "flex-start",
        margin: "15px 12vw",
        gap: "15px",
      }
  return (
    <div className="eczane-page">
      <TopRelatedCategory />
      <div style={eczaneleftcontainer}>
        <div className="eczane-Left-ad">
          <TitleBox title="Eczane" />

          <div className="eczane-left-ad-eczane-resmi-addeet">
            <img src={eczaneilac} alt="Eczane Alt Görseli" />
          </div>

          {/* Tab Butonları */}
          <div className="tab-buttons">
            <button
              className={selectedTab === "dun" ? "active" : ""}
              onClick={() => setSelectedTab("dun")}
            >
              Dün
            </button>
            <button
              className={selectedTab === "bugun" ? "active" : ""}
              onClick={() => setSelectedTab("bugun")}
            >
              Bugün
            </button>
            <button
              className={selectedTab === "yarin" ? "active" : ""}
              onClick={() => setSelectedTab("yarin")}
            >
              Yarın
            </button>
          </div>
          {/* İçerik */}
          <div className="eczane-content">
            {loading && <p>Yükleniyor...</p>}
            {error && <p>Hata: {error}</p>}
            {!loading && filteredPharmacies.length === 0 && (
              <p>Seçili tarih için veri bulunamadı.</p>
            )}
            <div>
              {filteredPharmacies.length > 0 && (
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: filteredPharmacies[0].content,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <SingleRelatedComponent />
        </div>
        <div className="eczane-side">
          <Side />
        </div>
      </div>
    </div>
  )
}

export default Eczane
